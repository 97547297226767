define('ember-plupload/system/upload-queue', ['exports', 'ember-plupload/system/file', 'ember-plupload/system/trim', 'ember-plupload/system/sum-by', 'ember-plupload/system/parsing'], function (exports, _file, _trim, _sumBy, _parsing) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  var copy = Ember.copy,
      merge = Ember.merge;

  var computed = Ember.computed;
  var bool = Ember.computed.bool;
  var bind = Ember.run.bind;
  var keys = Object.keys;

  var getHeader = function getHeader(headers, header) {
    var headerKeys = Ember.A(keys(headers));
    var headerIdx = headerKeys.map(function (s) {
      return s.toLowerCase();
    }).indexOf(header.toLowerCase());
    if (headerIdx !== -1) {
      return headers[headerKeys[headerIdx]];
    }
    return null;
  };

  /**
  
    @namespace ember-plupload
    @class UploadQueue
    @extend Ember.ArrayProxy
   */
  exports.default = Ember.ArrayProxy.extend({

    name: null,

    uploading: bool('length'),

    queues: null,

    init: function init() {
      set(this, 'queues', Ember.A([]));
      set(this, 'orphanedQueues', Ember.A([]));

      set(this, 'content', Ember.A([]));
      this._super();
    },
    configure: function configure() {
      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (config.browse_button) {
        Ember.assert('An element with the id "' + config.browse_button + '" is needed to match the uploader\'s for attribute.', document.getElementById(config.browse_button));
      }

      var uploader = new plupload.Uploader(config);

      uploader.bind('Init', bind(this, 'runtimeDidChange'));
      uploader.bind('FilesAdded', bind(this, 'filesAdded'));
      uploader.bind('FilesRemoved', bind(this, 'filesRemoved'));
      uploader.bind('BeforeUpload', bind(this, 'configureUpload'));
      uploader.bind('UploadProgress', bind(this, 'progressDidChange'));
      uploader.bind('FileUploaded', bind(this, 'fileUploaded'));
      uploader.bind('UploadComplete', bind(this, 'uploadComplete'));
      uploader.bind('Error', bind(this, 'onError'));

      get(this, 'queues').pushObject(uploader);

      // Set browse_button and drop_element as
      // references to the buttons so moxie doesn't
      // get confused when the dom might be detached
      uploader.settings.browse_button = [config.browse_button];
      if (config.drop_element) {
        uploader.settings.drop_element = [config.drop_element];
      }

      var settings = copy(uploader.settings);
      delete settings.url;
      set(this, 'settings', settings);

      uploader.init();
      return uploader;
    },
    runtimeDidChange: function runtimeDidChange() {
      var $input = get(this, 'target').element.querySelector('.moxie-shim input');
      var ruid = $input.getAttribute('id');
      var I = moxie.runtime.Runtime.getInfo(ruid);

      // Polyfill mobile support
      if (!I.can('summon_file_dialog')) {
        $input.getAttribute('capture', 'camera');
      }
    },


    /**
      Orphan the active plupload object so
      we garbage collect the queues.
     */
    orphan: function orphan() {
      var orphans = get(this, 'orphanedQueues');
      var activeQueues = get(this, 'queues').filter(function (queue) {
        return orphans.indexOf(queue) === -1;
      });
      var freshestQueue = get(Ember.A(activeQueues), 'lastObject');
      if (get(freshestQueue, 'total.queued') > 0) {
        orphans.pushObject(freshestQueue);
      } else {
        this.garbageCollectUploader(freshestQueue);
      }
    },
    destroy: function destroy() {
      this._super();
      get(this, 'queues').invoke('unbindAll');
      set(this, 'content', Ember.A([]));
      set(this, 'queues', null);
    },
    refresh: function refresh() {
      get(this, 'queues').invoke('refresh');
    },


    size: computed({
      get: function _get() {
        return (0, _sumBy.default)(get(this, 'queues'), 'total.size') || 0;
      }
    }),

    loaded: computed({
      get: function _get() {
        return (0, _sumBy.default)(get(this, 'queues'), 'total.loaded') || 0;
      }
    }),

    progress: computed('size', 'loaded', {
      get: function _get() {
        var percent = get(this, 'loaded') / get(this, 'size') || 0;
        return Math.floor(percent * 100);
      }
    }),

    filesAdded: function filesAdded(uploader, files) {
      for (var i = 0, len = files.length; i < len; i++) {
        var file = _file.default.create({
          uploader: uploader,
          file: files[i],
          queue: this
        });

        this.pushObject(file);
        get(this, 'target').sendAction('onfileadd', file, {
          name: get(this, 'name'),
          uploader: uploader,
          queue: this
        });

        this.notifyPropertyChange('size');
        this.notifyPropertyChange('loaded');
      }
    },
    filesRemoved: function filesRemoved(uploader, files) {
      for (var i = 0, len = files.length; i < len; i++) {
        var file = this.findBy('id', files[i].id);
        if (file) {
          this.removeObject(file);
        }
      }

      this.notifyPropertyChange('size');
      this.notifyPropertyChange('loaded');
    },
    configureUpload: function configureUpload(uploader, file) {
      file = this.findBy('id', file.id);
      // Reset settings for merging
      uploader.settings = copy(get(this, 'settings'));
      merge(uploader.settings, file.settings);

      this.progressDidChange(uploader, file);
    },
    progressDidChange: function progressDidChange(uploader, file) {
      file = this.findBy('id', file.id);
      if (file) {
        file.notifyPropertyChange('progress');
      }

      this.notifyPropertyChange('size');
      this.notifyPropertyChange('loaded');
    },
    parseResponse: function parseResponse(response) {
      var body = (0, _trim.default)(response.response);
      var rawHeaders = Ember.A(response.responseHeaders.split(/\n|\r/)).without('');
      var headers = rawHeaders.reduce(function (E, header) {
        var parts = header.split(/^([0-9A-Za-z_-]*:)/);
        if (parts.length > 0) {
          E[parts[1].slice(0, -1)] = (0, _trim.default)(parts[2]);
        }
        return E;
      }, {});

      var contentType = (getHeader(headers, 'Content-Type') || '').split(';');
      // Parse body according to the Content-Type received by the server
      if (contentType.indexOf('text/html') !== -1) {
        body = (0, _parsing.parseHTML)(body);
      } else if (contentType.indexOf('text/xml') !== -1) {
        body = Ember.$.parseXML(body); // TODO: replace with non jquery alternative
      } else if (contentType.indexOf('application/json') !== -1 || contentType.indexOf('text/javascript') !== -1 || contentType.indexOf('application/javascript') !== -1) {
        body = JSON.parse(body);
      }

      return {
        status: response.status,
        body: body,
        headers: headers
      };
    },
    fileUploaded: function fileUploaded(uploader, file, response) {
      var results = this.parseResponse(response);
      file = this.findBy('id', file.id);
      if (file) {
        this.removeObject(file);
      }

      // NOTE: Plupload calls UploadProgress upon triggering FileUploaded,
      //       so we don't need to trigger a progress event
      if (Math.floor(results.status / 200) === 1) {
        file._deferred.resolve(results);
      } else {
        file._deferred.reject(results);
      }

      // Notify plupload that our browse_button may have
      // changed locations
      Ember.run.later(uploader, 'refresh', 750);
    },
    garbageCollectUploader: function garbageCollectUploader(uploader) {
      get(this, 'queues').removeObject(uploader);
      get(this, 'orphanedQueues').removeObject(uploader);
      this.filterBy('uploader', uploader).invoke('destroy');
      uploader.unbindAll();
    },
    uploadComplete: function uploadComplete(uploader) {
      // Notify plupload that our browse_button may have
      // changed locations
      Ember.run.later(uploader, 'refresh', 750);
      this.notifyPropertyChange('loaded');
      this.notifyPropertyChange('size');

      // Clean up the orphaned uploader and its files
      if (get(this, 'orphanedQueues').indexOf(uploader) !== -1) {
        this.garbageCollectUploader(uploader);
      }
    },
    onError: function onError(uploader, error) {
      if (error.file) {
        var file = this.findBy('id', error.file.id);
        if (file == null) {
          file = _file.default.create({
            uploader: uploader,
            file: error.file
          });
        }

        set(file, 'error', error);

        if (file._deferred) {
          file._deferred.reject(error);

          // This happended before the file got queued,
          // So we need to stub out `upload` and trigger
          // the queued event
        } else {
          file.upload = file.read = function () {
            Ember.run.debounce(uploader, 'refresh', 750);
            return Ember.RSVP.reject(error, 'File: \'' + error.file.id + '\' ' + error.message);
          };
          if (file) {
            file.destroy();
          }

          get(this, 'target').sendAction('onfileadd', file, {
            name: get(this, 'name'),
            uploader: uploader,
            queue: this
          });
        }
        this.notifyPropertyChange('length');
        Ember.run.debounce(uploader, 'refresh', 750);
      } else {
        set(this, 'error', error);
        get(this, 'target').sendAction('onerror', error);
      }
    }
  });
});