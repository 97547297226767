define("ember-google-maps/utils/options-and-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEventListener = addEventListener;
  _exports.addEventListeners = addEventListeners;
  _exports.isEvent = isEvent;
  _exports.isIgnored = isIgnored;
  _exports.parseOptionsAndEvents = parseOptionsAndEvents;
  _exports.watch = watch;
  _exports.ignoredOptions = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ignoredOptions = ['map', 'lat', 'lng', '_internalAPI', 'gMap', 'options', 'events', '_name'];
  _exports.ignoredOptions = ignoredOptions;

  function parseOptionsAndEvents() {
    var ignored = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (r) {
      return r;
    };
    var ignoredSet = new Set(ignored);
    return Ember.computed('attrs', function () {
      return callback(parseAttrs(ignoredSet, this.attrs));
    });
  }

  function parseAttrs() {
    var ignored = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Set();
    var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var events = _objectSpread({}, args.events);

    var options = _objectSpread({}, args.options);

    var entries = Object.entries(args);
    entries.forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          k = _ref2[0],
          v = _ref2[1];

      if (isIgnored(k, ignored)) {// Pass
      } else if (isEvent(k)) {
        events[k] = v;
      } else {
        options[k] = extractValue(v);
      }
    });
    return {
      events: events,
      options: options
    };
  }

  function isEvent(key) {
    return key.slice(0, 2) === 'on';
  }

  function isIgnored(key, ignored) {
    return ignored.has(key);
  }

  function extractValue(cell) {
    if (cell && cell.constructor && Object.keys(cell).includes('value')) {
      return cell.value;
    }

    return cell;
  }

  function watch(target) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Object.entries(options).map(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          key = _ref4[0],
          callback = _ref4[1];

      return addObserver(target, key, callback);
    });
  }

  function addObserver(obj, key, callback) {
    var listener = obj.addObserver(key, callback);
    return {
      name: key,
      listener: listener,
      remove: function remove() {
        return obj.removeObserver(key, callback);
      }
    };
  }
  /* Events */


  function addEventListener(target, originalEventName, action) {
    var payload = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var eventName = Ember.String.decamelize(originalEventName).slice(3);

    function callback(googleEvent) {
      var params = _objectSpread({
        event: window.event,
        googleEvent: googleEvent,
        eventName: eventName,
        target: target
      }, payload);

      Ember.run.join(target, action, params);
    }

    var listener = google.maps.event.addDomListener(target, eventName, callback);
    return {
      name: eventName,
      listener: listener,
      remove: function remove() {
        return listener.remove();
      }
    };
  }
  /**
   * Add event listeners on a target object using the cross-browser event
   * listener library provided by the Google Maps API.
   *
   * @param {Object} target
   * @param {Events} events
   * @param {[Object]} payload = {} An optional object of additional parameters
   *     to include with the event payload.
   * @return {google.maps.MapsEventListener[]} An array of bound event listeners
   *     that should be used to remove the listeners when no longer needed.
   */


  function addEventListeners(target, events) {
    var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return Object.entries(events).map(function (_ref5) {
      var _ref6 = _slicedToArray(_ref5, 2),
          originalEventName = _ref6[0],
          action = _ref6[1];

      return addEventListener(target, originalEventName, action, payload);
    });
  }
});