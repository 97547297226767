define("@zestia/ember-dragula/templates/components/ember-dragula-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C0wm517S",
    "block": "{\"symbols\":[\"&attrs\",\"@onInsert\",\"@onDestroy\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"ember-dragula__container\"],[13,1],[3,\"did-insert\",[[23,2,[]]]],[3,\"will-destroy\",[[23,3,[]]]],[8],[0,\"\\n  \"],[14,4],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@zestia/ember-dragula/templates/components/ember-dragula-container.hbs"
    }
  });

  _exports.default = _default;
});