define("ember-responsive-tabs/mixins/component-parent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    children: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('children', Ember.A());
    },
    registerChild: function registerChild(child) {
      Ember.run.next(this, function () {
        this.get('children').addObject(child);
      });
    },
    removeChild: function removeChild(child) {
      this.get('children').removeObject(child);
    }
  });

  _exports.default = _default;
});