define("ember-changeset/utils/get-key-values", ["exports", "ember-changeset/utils/is-object", "ember-changeset/-private/err"], function (_exports, _isObject, _err) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getKeyValues = getKeyValues;
  _exports.getKeyErrorValues = getKeyErrorValues;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var keysUpToValue = [];
  /**
   * traverse through target and return leaf nodes with `value` property and key as 'person.name'
   *
   * @method getKeyValues
   * @return {Array} [{ 'person.name': value }]
   */

  function getKeyValues(obj) {
    var map = [];

    for (var key in obj) {
      keysUpToValue.push(key);

      if (obj[key] && (0, _isObject.default)(obj[key])) {
        if (Object.prototype.hasOwnProperty.call(obj[key], 'value')) {
          map.push({
            key: keysUpToValue.join('.'),
            value: obj[key].value
          }); // stop collecting keys

          keysUpToValue = [];
        } else if (key !== 'value') {
          map.push.apply(map, _toConsumableArray(getKeyValues(obj[key])));
        }
      }
    }

    keysUpToValue = [];
    return map;
  }

  var errorKeysUpToValue = [];
  /**
   * traverse through target and return leaf nodes with `value` property and key as 'person.name'
   *
   * @method getKeyErrorValues
   * @return {Array} [{ key: 'person.name', validation: '', value: '' }]
   */

  function getKeyErrorValues(obj) {
    var map = [];

    for (var key in obj) {
      errorKeysUpToValue.push(key);

      if (obj[key] && (0, _isObject.default)(obj[key])) {
        if (Object.prototype.hasOwnProperty.call(obj[key], 'value') && obj[key] instanceof _err.default) {
          map.push({
            key: errorKeysUpToValue.join('.'),
            validation: obj[key].validation,
            value: obj[key].value
          }); // stop collecting keys

          errorKeysUpToValue = [];
        } else if (key !== 'value') {
          map.push.apply(map, _toConsumableArray(getKeyErrorValues(obj[key])));
        }
      }
    }

    errorKeysUpToValue = [];
    return map;
  }
});