define("ember-changeset/-private/evented", ["exports", "ember-changeset/-private/notifier"], function (_exports, _notifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notifierForEvent = notifierForEvent;

  function notifierForEvent(object, eventName) {
    if (object._eventedNotifiers === undefined) {
      object._eventedNotifiers = {};
    }

    var notifier = object._eventedNotifiers[eventName];

    if (!notifier) {
      notifier = object._eventedNotifiers[eventName] = new _notifier.default();
    }

    return notifier;
  }
});