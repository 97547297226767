define("ember-changeset/utils/get-deep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getDeep;

  /**
   * Handles both single key or nested string keys ('person.name')
   *
   * @method getDeep
   */
  function getDeep(root, path) {
    var obj = root;

    if (path.indexOf('.') === -1) {
      return obj[path];
    }

    var parts = typeof path === 'string' ? path.split('.') : path;

    for (var i = 0; i < parts.length; i++) {
      if (obj === undefined || obj === null) {
        return undefined;
      } // next iteration has next level


      obj = obj[parts[i]];
    }

    return obj;
  }
});