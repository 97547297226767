define('ember-cli-uuid/mixins/adapters/uuid', ['exports', 'ember-cli-uuid'], function (exports, _emberCliUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.generateIdForRecord = generateIdForRecord;
  function generateIdForRecord() {
    return (0, _emberCliUuid.uuid)();
  }

  exports.default = Ember.Mixin.create({ generateIdForRecord: generateIdForRecord });
});