define('ember-plupload/services/uploader', ['exports', 'ember-plupload/system/upload-queue', 'ember-plupload/system/flatten'], function (exports, _uploadQueue, _flatten) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var _get = Ember.get;
  var set = Ember.set;
  var computed = Ember.computed;

  exports.default = Ember.Service.extend({

    /**
      @private
      Setup a map of uploaders so they may be
      accessed by name via the `find` method.
     */
    init: function init() {
      this._super.apply(this, arguments);

      set(this, 'queues', new Map());
      set(this, 'all', Ember.A());
    },


    files: computed('all.@each.length', {
      get: function get() {
        return (0, _flatten.default)(_get(this, 'all').invoke('toArray'));
      }
    }),

    size: computed('all.@each.size', {
      get: function get() {
        return Ember.A(_get(this, 'all').getEach('size')).reduce(function (E, x) {
          return E + x;
        }, 0);
      }
    }),

    loaded: computed('all.@each.loaded', {
      get: function get() {
        return Ember.A(_get(this, 'all').getEach('loaded')).reduce(function (E, x) {
          return E + x;
        }, 0);
      }
    }),

    progress: Ember.computed('size', 'loaded', function () {
      var percent = _get(this, 'loaded') / _get(this, 'size') || 0;
      return Math.floor(percent * 100);
    }),

    /**
      Return or instantiate a new plupload instance
      for an upload queue.
       @method find
      @param {String} name The name of the queue to find
      @param {Object} [config] The configuration to use for the uploader
     */
    findOrCreate: function findOrCreate(name, component, config) {
      var queue;

      if (_get(this, 'queues').has(name)) {
        queue = _get(this, 'queues').get(name);
        if (config != null) {
          set(queue, 'target', component);
          queue.configure(config);
        }
      } else {
        queue = _uploadQueue.default.create({
          name: name,
          target: component
        });
        _get(this, 'all').pushObject(queue);
        _get(this, 'queues').set(name, queue);
        queue.configure(config);
      }
      return queue;
    }
  });
});