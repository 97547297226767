define("ember-responsive-tabs/templates/components/fullwidth-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KcFwIglJ",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[7,\"nav\",true],[8],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"navItems\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[24,[\"isActiveId\"]],[23,1,[\"elementId\"]]],null],\"tab-current\",\"\"],null]],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"href\",[29,[\"#\",[23,1,[\"elementId\"]]]]],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"select\",[23,1,[\"elementId\"]]],null]]],[8],[0,\"\\n          \"],[7,\"i\",true],[11,\"class\",[29,[[23,1,[\"icon\"]]]]],[8],[9],[0,\"\\n          \"],[7,\"span\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"pane\",\"activeId\",\"select\"],[[28,\"component\",[\"fullwidth-tabs.tab-pane\"],[[\"parent\",\"activeId\"],[[23,0,[]],[24,[\"isActiveId\"]]]]],[24,[\"isActiveId\"]],[28,\"action\",[[23,0,[]],\"select\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-responsive-tabs/templates/components/fullwidth-tabs.hbs"
    }
  });

  _exports.default = _default;
});