define("ember-changeset/utils/normalize-object", ["exports", "ember-changeset/utils/is-object"], function (_exports, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeObject;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * traverse through target and unset `value` from leaf key so can access normally
   * {
   *  name: Change {
   *    value: 'Charles'
   *  }
   * }
   *
   * to
   *
   * {
   *  name: 'Charles'
   * }
   *
   * Shallow copy here is fine because we are swapping out the leaf nested object
   * rather than mutating a property in something with reference
   *
   * @method normalizeObject
   * @param target
   */
  function normalizeObject(target) {
    if ('value' in target) {
      return target.value;
    }

    var obj = _objectSpread({}, target);

    for (var _key in obj) {
      if (_key === 'value') {
        return obj[_key];
      }

      if (obj[_key] && (0, _isObject.default)(obj[_key])) {
        if (Object.prototype.hasOwnProperty.call(obj[_key], 'value')) {
          obj[_key] = obj[_key].value;
        } else {
          obj[_key] = normalizeObject(obj[_key]);
        }
      }
    }

    return obj;
  }
});