define("ember-google-maps/components/g-map/control", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/control"], function (_exports, _mapComponent, _control) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @class Control
   * @namespace GMap
   * @module ember-google-maps/components/g-map/control
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    layout: _control.default,
    class: undefined,
    tagName: 'div',
    _type: 'control',
    _createOptions: function _createOptions(options) {
      return _objectSpread({}, options, {
        position: Ember.get(this, 'position'),
        map: this.map
      });
    },
    _addComponent: function _addComponent() {
      var _elementDestination = Ember.set(this, '_elementDestination', document.createElement('div'));

      var map = Ember.get(this, 'map');

      if (Ember.get(this, 'class')) {
        _elementDestination.classList.add(Ember.get(this, 'class'));
      }

      var controlPosition = google.maps.ControlPosition[Ember.get(this, 'position')];
      map.controls[controlPosition].push(_elementDestination);
      return Ember.RSVP.resolve(Ember.set(this, 'mapComponent', _elementDestination));
    },
    _updateComponent: function _updateComponent() {}
  });

  _exports.default = _default;
});