define("@zestia/ember-dragula/templates/components/ember-dragula", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OnA7aE11",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"ember-dragula\"],[13,1],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"Container\"],[[28,\"component\",[\"ember-dragula-container\"],[[\"onInsert\",\"onDestroy\"],[[23,0,[\"addContainer\"]],[23,0,[\"removeContainer\"]]]]]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@zestia/ember-dragula/templates/components/ember-dragula.hbs"
    }
  });

  _exports.default = _default;
});