define("ember-changeset/utils/set-deep", ["exports", "ember-changeset/-private/change"], function (_exports, _change) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setDeep;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * TODO: consider
   * https://github.com/emberjs/ember.js/blob/822452c4432620fc67a777aba3b150098fd6812d/packages/%40ember/-internals/metal/lib/property_set.ts
   *
   * Handles both single path or nested string paths ('person.name')
   *
   * @method setDeep
   */
  function setDeep(target, path, value) {
    var keys = split(path).filter(isValidKey); // We will mutate target and through complex reference, we will mutate the orig

    var orig = target;

    if (keys.length === 1) {
      target[path] = value;
      return target;
    }

    for (var i = 0; i < keys.length; i++) {
      var prop = keys[i];
      var obj = isObject(target[prop]);

      if (!obj) {
        target[prop] = {};
      } else if (obj && target[prop] instanceof _change.default) {
        // we don't want to merge new changes with a Change instance higher up in the obj tree
        target[prop] = {};
      } // last iteration


      if (i === keys.length - 1) {
        result(target, prop, value);
        break;
      } // assign next level of object for next loop


      target = target[prop];
    }

    return orig;
  } // to avoid overwriting child keys of leaf node


  function result(target, path, value) {
    target[path] = value;
  }

  function split(path) {
    var keys = path.split('.');
    return keys;
  }

  function isValidKey(key) {
    return key !== '__proto__' && key !== 'constructor' && key !== 'prototype';
  }

  function isObject(val) {
    return val !== null && (_typeof(val) === 'object' || typeof val === 'function');
  } // function isPlainObject(o: unknown): o is object {
  //   return Object.prototype.toString.call(o) === '[object Object]';
  // }

});