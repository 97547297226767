define('ember-plupload/system/parsing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Parse Html function copied from https://github.com/nefe/You-Dont-Need-jQuery#v2.0
   */
  function parseHTML(string) {
    var context = document.implementation.createHTMLDocument();

    // Set the base href for the created document so any parsed elements with URLs
    // are based on the document's URL
    var base = context.createElement('base');
    base.href = document.location.href;
    context.head.appendChild(base);

    context.body.innerHTML = string;
    return context.body.children;
  }

  // TODO: Parse XML function to replace jQuery.parseXml
  exports.parseHTML = parseHTML;
});