define('ember-select-box/mixins/select-box/select-box/searchable', ['exports', 'ember-select-box/utils/invoke-action'], function (exports, _invokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isSearchable: Ember.computed(function () {
      return typeof this.get('on-search') === 'function';
    }),

    searchDelayTime: Ember.computed(function () {
      return this.getWithDefault('search-delay-time', 100);
    }),

    searchSlowTime: Ember.computed(function () {
      return this.getWithDefault('search-slow-time', 500);
    }),

    searchMinChars: Ember.computed(function () {
      return this.getWithDefault('search-min-chars', 1);
    }),

    queryOK: function queryOK(query) {
      return query.length >= this.get('searchMinChars');
    },
    _inputText: function _inputText(text) {
      if (this.get('isSearchable')) {
        this._runDebouncedSearch(text);
      }
    },
    _runDebouncedSearch: function _runDebouncedSearch(query) {
      var delay = this.get('searchDelayTime');
      var immediate = !delay;
      Ember.run.debounce(this, '_runSearch', query, delay, immediate);
    },
    _runSearch: function _runSearch(query) {
      query = ('' + query).trim();
      if (this.queryOK(query) && !this.get('isDestroyed')) {
        this._search(query);
      }
    },
    _search: function _search() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      this.set('isSearching', true);
      this.incrementProperty('searchID');
      Ember.run.debounce(this, '_checkSlowSearch', this.get('searchSlowTime'));

      var search = this.get('on-search');
      return Ember.RSVP.resolve(search(query, this.get('api'))).then(Ember.run.bind(this, '_searchCompleted', this.get('searchID'), query)).catch(Ember.run.bind(this, '_searchFailed', query));
    },
    _searchCompleted: function _searchCompleted(id, query, result) {
      if (this.get('isDestroyed')) {
        return;
      }

      var superseded = id < this.get('searchID');
      if (superseded) {
        return;
      }

      (0, _invokeAction.default)(this, 'on-searched', result, query, this.get('api'));
      this._searchFinished();
    },
    _searchFailed: function _searchFailed(query, error) {
      if (this.get('isDestroyed')) {
        return;
      }
      (0, _invokeAction.default)(this, 'on-search-error', error, query, this.get('api'));
      this._searchFinished();
    },
    _searchFinished: function _searchFinished() {
      this.set('isSearching', false);
      this.set('isSlowSearch', false);
    },
    _checkSlowSearch: function _checkSlowSearch() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('isSlowSearch', this.get('isSearching'));
    },


    actions: {
      search: function search(query) {
        return this._search(query);
      },
      stopSearching: function stopSearching() {
        this.incrementProperty('searchID');
        this._searchFinished();
      },
      setInputValue: function setInputValue(value) {
        this.set('input.element.value', value);
      },
      focusInput: function focusInput() {
        this.get('input.element').focus();
      },
      blurInput: function blurInput() {
        this.get('input.element').blur();
      },
      inputText: function inputText(text) {
        this._super.apply(this, arguments);
        this._inputText(text);
      }
    }
  });
});