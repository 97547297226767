define("ember-responsive-tabs/components/fullwidth-tabs", ["exports", "ember-responsive-tabs/templates/components/fullwidth-tabs", "ember-responsive-tabs/mixins/component-parent", "ember-responsive-tabs/components/fullwidth-tabs/tab-pane"], function (_exports, _fullwidthTabs, _componentParent, _tabPane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentParent.default, {
    layout: _fullwidthTabs.default,
    isOpen: false,
    classNames: ['tabs'],
    classNameBindings: ['isOpen:tabs-open'],
    childPanes: Ember.computed.filter('children', function (view) {
      return view instanceof _tabPane.default;
    }),
    activeId: Ember.computed.oneWay('childPanes.firstObject.elementId'),
    isActiveId: Ember.computed('activeId', {
      get: function get() {
        return Ember.getWithDefault(this, 'activeId', null);
      },
      set: function set(key, value) {
        return value;
      }
    }),
    navItems: Ember.computed('childPanes.@each.{elementId,title,icon}', function () {
      var items = Ember.A();
      this.get('childPanes').forEach(function (pane) {
        var item = pane.getProperties('elementId', 'title', 'icon');
        items.pushObject(item);
      });
      return items;
    }),
    actions: {
      select: function select(id) {
        this.set('isOpen', this.get('isActiveId') === id && !this.get('isOpen'));
        this.set('isActiveId', id);
      }
    }
  });

  _exports.default = _default;
});