define("ember-changeset/helpers/changeset-set", ["exports", "ember-changeset/utils/is-changeset"], function (_exports, _isChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changesetSet = changesetSet;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This is a drop in replacement for the `mut` helper
   *
   * @method changesetSet
   * @param params
   */
  function changesetSet(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        obj = _ref2[0],
        path = _ref2[1];

    if ((0, _isChangeset.default)(obj)) {
      return function (value) {
        return obj.set(path, value);
      };
    }
  }

  var _default = Ember.Helper.helper(changesetSet);

  _exports.default = _default;
});