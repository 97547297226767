define('ember-cli-data-export/components/export-selector-onselect', ['exports', 'ember-cli-data-export/templates/components/export-selector-onselect'], function (exports, _exportSelectorOnselect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _exportSelectorOnselect.default,
    selectPrompt: 'Export File',

    actions: {
      triggerExport: function triggerExport(selectedType) {
        this.sendAction('exportData', selectedType);
        this.$('#exportTypes').val('');
      }
    }
  });
});