define("ember-changeset/utils/merge-nested", ["exports", "ember-changeset/utils/set-deep"], function (_exports, _setDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mergeNested;
  var keys = Object.keys;
  /**
   * Given an array of objects, merge their keys into a new object and
   * return the new object.
   *
   * This function merges using `setNestedProperty`.
   */

  function mergeNested() {
    var finalObj = {};

    for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    objects.forEach(function (obj) {
      return keys(obj).forEach(function (key) {
        return (0, _setDeep.default)(finalObj, key, obj[key]);
      });
    });
    return finalObj;
  }
});