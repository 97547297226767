define('ember-cli-data-export/components/export-selector', ['exports', 'ember-cli-data-export/templates/components/export-selector'], function (exports, _exportSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _exportSelector.default,
    types: Ember.computed(function () {
      return [this.get('selectPrompt'), 'Excel', 'CSV'];
    }),
    selectedType: null,
    actionText: 'Export',
    selectPrompt: 'Export File',

    actions: {
      triggerExport: function triggerExport() {
        this.sendAction('exportData', this.get('selectedType'));
      }
    }
  });
});