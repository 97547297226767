define('ember-plupload/components/pl-uploader', ['exports', 'dinosheets', 'ember-plupload/system/trim', 'ember-plupload/computed/w'], function (exports, _dinosheets, _trim, _w) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var _get = Ember.get;
  var set = Ember.set;
  var keys = Object.keys;

  var bind = Ember.run.bind;
  var computed = Ember.computed;

  var isDragAndDropSupported = function () {
    var supported = null;
    return function (runtimes) {
      if (runtimes.indexOf('html5') === -1) {
        return false;
      }

      if (supported == null) {
        supported = 'draggable' in document.createElement('span');
      }
      return supported;
    };
  }();

  var styleSheet;
  var sharedStyleSheet = function sharedStyleSheet() {
    if (styleSheet == null) {
      styleSheet = new _dinosheets.default();
    }
    return styleSheet;
  };

  var slice = Array.prototype.slice;

  exports.default = Ember.Component.extend({
    classNames: ['pl-uploader'],

    name: null,

    'for-dropzone': null,

    onfileadd: null,
    onerror: null,

    uploader: Ember.inject.service(),

    /**
      A cascading list of runtimes to fallback on to
      for uploading files with.
       @property runtimes
      @type String[]
      @default ['html5', 'html4', 'flash', 'silverlight']
     */
    runtimes: (0, _w.default)(['html5', 'html4', 'flash', 'silverlight']),
    extensions: (0, _w.default)(),

    'max-file-size': 0,
    'no-duplicates': false,

    multiple: true,
    'unique-names': false,
    'send-browser-cookies': false,
    'send-file-name': null,

    dropzone: computed('for-dropzone', {
      get: function get() {
        var dropzone = {};
        var id = _get(this, 'for-dropzone') || 'dropzone-for-' + _get(this, 'elementId');
        dropzone.enabled = false;

        if (isDragAndDropSupported(_get(this, 'runtimes'))) {
          dropzone.enabled = true;
          dropzone.id = id;
          dropzone.data = null;
          dropzone['drag-and-drop'] = {
            'dropzone-id': id,
            'drag-data': null
          };
        }
        return dropzone;
      }
    }),

    config: computed({
      get: function get() {
        var _this = this;

        var config = {
          url: true, // Required to init plupload
          browse_button: _get(this, 'for'),
          filters: {
            max_file_size: _get(this, 'max-file-size'),
            prevent_duplicates: _get(this, 'no-duplicates')
          },

          multi_selection: _get(this, 'multiple'),

          runtimes: _get(this, 'runtimes').join(','),
          container: _get(this, 'elementId'),
          flash_swf_url: this.BASE_URL + 'Moxie.swf',
          silverlight_xap_url: this.BASE_URL + 'Moxie.xap',
          unique_names: _get(this, 'unique-names'),
          required_features: {}
        };

        if (_get(this, 'send-browser-cookies')) {
          config.required_features.send_browser_cookies = true;
        }

        if (_get(this, 'send-file-name') != null) {
          config.send_file_name = _get(this, 'send-file-name');
        }

        var filters = _get(this, 'fileFilters') || {};
        keys(filters).forEach(function (filter) {
          if (_get(_this, filter)) {
            config.filters[filter] = _get(_this, filter);
          }
        });

        if (isDragAndDropSupported(_get(this, 'runtimes'))) {
          config.drop_element = _get(this, 'dropzone.id');
        }

        if (_get(this, 'extensions.length')) {
          config.filters.mime_types = [{
            extensions: _get(this, 'extensions').map(function (ext) {
              return ext.toLowerCase();
            }).join(',')
          }];
        }

        return config;
      }
    }),

    didInsertElement: Ember.on('didInsertElement', function () {
      Ember.run.scheduleOnce('afterRender', this, 'attachUploader');
      Ember.run.scheduleOnce('afterRender', this, 'setupDragListeners');
    }),

    attachUploader: function attachUploader() {
      var uploader = _get(this, 'uploader');
      var queue = uploader.findOrCreate(_get(this, 'name'), this, _get(this, 'config'));
      set(this, 'queue', queue);

      // Send up the pluploader object so the app implementing this component as has access to it
      var pluploader = queue.get('queues.firstObject');
      this.sendAction('onInitOfUploader', pluploader);
      this._dragInProgress = false;
      this._invalidateDragData();
    },
    setupDragListeners: function setupDragListeners() {
      var dropzoneId = _get(this, 'dropzone.id');
      if (dropzoneId) {
        var handlers = this.eventHandlers = {
          dragenter: bind(this, 'enteredDropzone'),
          dragleave: bind(this, 'leftDropzone')
        };

        keys(handlers).forEach(function (key) {
          document.getElementById(dropzoneId).addEventListener(key, function (e) {
            handlers[key](e);
          });
        });
      }
    },


    detachUploader: Ember.on('willDestroyElement', function () {
      var queue = _get(this, 'queue');
      if (queue) {
        queue.orphan();
        set(this, 'queue', null);
      }
      var sheet = sharedStyleSheet();
      sheet.css('#' + _get(this, 'dropzone.id') + ' *', null);
      sheet.applyStyles();
    }),

    teardownDragListeners: Ember.on('willDestroyElement', function () {
      var dropzoneId = _get(this, 'dropzone.id');
      if (dropzoneId) {
        var handlers = this.eventHandlers;
        keys(handlers).forEach(function (key) {
          document.getElementById(dropzoneId).removeEventListener(key, handlers[key]);
        });
        this.eventHandlers = null;
      }
    }),

    dragData: null,
    enteredDropzone: function enteredDropzone(evt) {
      if (this._dragInProgress === false) {
        this._dragInProgress = true;
        this.activateDropzone(evt);
      }
    },
    leftDropzone: function leftDropzone() {
      if (this._dragInProgress === true) {
        this._dragInProgress = false;
        this.deactivateDropzone();
      }
    },
    activateDropzone: function activateDropzone(evt) {
      var sheet = sharedStyleSheet();
      sheet.css('#' + _get(this, 'dropzone.id') + ' *', {
        pointerEvents: 'none'
      });
      Ember.run.scheduleOnce('render', sheet, 'applyStyles');
      set(this, 'dragData', _get(evt, 'dataTransfer'));
    },
    deactivateDropzone: function deactivateDropzone() {
      var sheet = sharedStyleSheet();
      sheet.css('#' + _get(this, 'dropzone.id') + ' *', null);
      Ember.run.scheduleOnce('render', sheet, 'applyStyles');

      this._dragInProgress = false;
      set(this, 'dragData', null);
    },


    _invalidateDragData: Ember.observer('queue.length', function () {
      // Looks like someone dropped a file
      var filesAdded = _get(this, 'queue.length') > this._queued;
      var filesDropped = _get(this, 'queue.length') === this._queued;
      if ((filesAdded || filesDropped) && _get(this, 'dragData')) {
        this.deactivateDropzone();
      }
      this._queued = _get(this, 'queue.length');
      Ember.run.scheduleOnce('afterRender', this, 'refreshQueue');
    }),

    refreshQueue: function refreshQueue() {
      var queue = this.get('queue');

      if (queue) {
        queue.refresh();
      }
    },


    setDragDataValidity: Ember.observer('dragData', Ember.on('init', function () {
      if (!isDragAndDropSupported(_get(this, 'runtimes'))) {
        return;
      }

      var data = _get(this, 'dragData');
      var extensions = _get(this, 'extensions');
      var isValid = true;

      // Validate
      if (extensions.length) {
        isValid = slice.call(_get(data || {}, 'items') || []).every(function (item) {
          var fileType = (0, _trim.default)(item.type).toLowerCase();
          return extensions.any(function (ext) {
            return new RegExp(ext + '$').test(fileType);
          });
        });
      }

      if (data) {
        set(this, 'dropzone.active', true);
        set(this, 'dropzone.valid', isValid);
      } else {
        set(this, 'dropzone.active', false);
        set(this, 'dropzone.valid', null);
      }
    }))
  });
});