define('ember-mailcheck/components/mail-check', ['exports', 'ember', 'mailcheck'], function (exports, _ember, _mailcheck) {
  exports['default'] = _ember['default'].Component.extend({
    type: 'email',
    name: 'email',
    value: '',
    placeholder: '',
    suggestion: '',
    hintMessage: 'You seem to be missing an email domain, like @gmail.com or @hotmail.com',
    suggestionMessage: 'Did you mean',
    inputClass: 'mailcheck-input',
    classNames: ['mailcheck'],
    disabled: false,
    required: false,
    title: null,

    actions: {
      mailcheck: function mailcheck() {
        var _this = this;

        _mailcheck['default'].run({
          email: _this.value,
          suggested: function suggested(suggestion) {
            _this.set('hint', _this.get('suggestionMessage') + ' ');
            _this.set('suggestion', suggestion.full);
          },
          empty: function empty() {
            var email = _this.value;

            if (isEmail(email)) {
              _this.set('suggestion', null);
              _this.set('hint', null);
              return;
            }

            _this.set('hint', _this.get('hintMessage'));
            _this.set('suggestion', null);
          }
        });
      },

      useSuggestion: function useSuggestion() {
        this.set('value', this.get('suggestion'));
        this.set('suggestion', null);
        this.set('hint', null);
      }
    }
  });

  function isEmail(email) {
    return _ember['default'].isPresent(email) && /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/.test(email);
  }
});