define("ember-cli-data-export/utils/utils", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = optionize;
  function optionize(options, defaultConfig) {
    if (Object.prototype.toString.call(options) === "[object Object]") {
      options = options || {};
    } else {
      options = {};
    }

    for (var property in defaultConfig) {
      options[property] = typeof options[property] !== 'undefined' ? options[property] : defaultConfig[property];
    }

    return options;
  }
});