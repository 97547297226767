define('ember-cli-uuid/utils/uuid-helpers', ['exports', 'uuid'], function (exports, _uuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.uuid = uuid;
  function uuid() {
    return (0, _uuid.v4)();
  }

  exports.default = uuid;
});