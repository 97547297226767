define("ember-responsive-tabs/components/fullwidth-tabs/tab-pane", ["exports", "ember-responsive-tabs/templates/components/fullwidth-tabs/tab-pane", "ember-responsive-tabs/mixins/component-child"], function (_exports, _tabPane, _componentChild) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentChild.default, {
    layout: _tabPane.default,
    classNameBindings: ['contentCurrent'],
    tagName: 'section',
    activeId: null,
    isActive: Ember.computed('activeId', 'elementId', function () {
      return this.get('activeId') === this.get('elementId');
    }).readOnly(),
    contentCurrent: true,
    show: function show() {
      this.set('contentCurrent', true);
    },
    hide: function hide() {
      this.set('contentCurrent', false);
    },
    _showHide: Ember.observer('isActive', function () {
      if (this.get('isActive')) {
        this.show();
      } else {
        this.hide();
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        this.set('contentCurrent', this.get('isActive'));
      });
    }
  });

  _exports.default = _default;
});