define('ember-countup/components/count-up', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['ember-countup'],
    tagName: 'span',

    didInsertElement: function didInsertElement() {
      this._insertAndStartCountUp();
    },

    changed: _ember['default'].observer('startVal', 'endVal', 'decimals', 'duration', 'useEasing', 'easingFn', 'useGrouping', 'separator', 'decimal', 'prefix', 'suffix', 'formattingFn', 'onComplete', function () {
      this._insertAndStartCountUp();
    }),

    _insertAndStartCountUp: function _insertAndStartCountUp() {
      var _this = this;

      _ember['default'].run.next(function () {
        var countup = new CountUp(_this.get('elementId'), _this.get('startVal') || 0, _this.get('endVal') || 0, _this.get('decimals') || 0, _this.get('duration') || 2, {
          useEasing: _this.get('useEasing'),
          easingFn: _this.get('easingFn'),
          useGrouping: _this.get('useGrouping'),
          separator: _this.get('separator') || ',',
          decimal: _this.get('decimal') || '.',
          prefix: _this.get('prefix') || '',
          suffix: _this.get('suffix') || '',
          formattingFn: _this.get('formattingFn')
        });

        if (_this.attrs.onComplete) {
          countup.start(_this.attrs.onComplete);
        } else {
          countup.start();
        }
      });
    }
  });
});